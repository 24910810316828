import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/auth/';

const register = (user) => {
  return axios.post(`${API_URL}register`, user)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const message = error.response?.data?.message || 'Registration failed';
      throw new Error(message);
    });
};


const login = (user) => {
  return axios.post(`${API_URL}login`, user)
    .then(response => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    })
    .catch(error => {
      const message = error.response?.data?.message || 'Login failed';
      throw new Error(message);
    });
};


const logout = () => {
  localStorage.removeItem('token');
};

const requestPasswordReset = (email) => {
  return axios.post(`${API_URL}reset`, { email })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('Failed to request password reset:', error);
      throw error;
    });
};

const resetPassword = (resetToken, newPassword) => {
  return axios.post(`${API_URL}reset/${resetToken}`, { newPassword })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('Failed to reset password:', error);
      throw error;
    });
};

const activateAccount = (activationCode) => {
  return axios.get(`${API_URL}activate/${activationCode}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("URL",`${API_URL}activate/${activationCode}`)
      console.log('Failed to activate account:', error);
      throw error;
    });
};

export default {
  register,
  login,
  logout,
  requestPasswordReset,
  resetPassword,
  activateAccount,
};