<template>
  <div class="container mt-5">
    <h1>Prijava</h1>
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="identifier">Korisničko ime ili Email</label>
        <input type="text" v-model="identifier" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="password">Lozinka</label>
        <input type="password" v-model="password" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary">Prijava</button>
    </form>
    <div class="mt-3">
      <router-link to="/password-reset">Zaboravili ste lozinku?</router-link>
    </div>
    <div class="mt-2">
      <p>Nemate račun? <router-link to="/register">Registrirajte se</router-link></p>
    </div>
  </div>
</template>

<script>
import { useStore } from '../store';

export default {
  name: 'LoginView',
  data() {
    return {
      identifier: '',
      password: '',
      errorMessage: '',
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    async login() {
      try {
        this.errorMessage = '';
        await this.store.login({ identifier: this.identifier, password: this.password });
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>
