<template>
  <div class="container mt-5">
    <h2>Pretraga Proizvoda</h2>
    <form @submit.prevent="fetchSearchResults">
      <div class="row mb-4">
        <div class="col-md-4">
          <input v-model="query.name" type="text" class="form-control" placeholder="Naziv proizvoda">
        </div>
        <div class="col-md-4">
          <select v-model="query.category" class="form-control">
            <option value="">Odaberite kategoriju</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
          </select>
        </div>
        <div class="col-md-2">
          <input v-model="query.minPrice" type="number" class="form-control" placeholder="Min cijena">
        </div>
        <div class="col-md-2">
          <input v-model="query.maxPrice" type="number" class="form-control" placeholder="Max cijena">
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Pretraži</button>
    </form>

    <ProductList :filteredProducts="products" />
  </div>
</template>

<script>
import productService from '../services/productService';
import categoryService from '../services/categoryService';
import ProductList from '../components/ProductList.vue';

export default {
  name: 'SearchComponent',
  components: {
    ProductList
  },
  data() {
    return {
      products: [],
      categories: [],
      query: {
        name: '',
        category: '',
        minPrice: null,
        maxPrice: null,
      }
    };
  },
  methods: {
    async fetchSearchResults() {
      try {
        this.products = await productService.searchProducts(this.query);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await categoryService.getAllCategories();
        this.categories = response.data.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
  },
  async created() {
    this.query = { ...this.$route.query };
    await this.fetchCategories();
    this.fetchSearchResults();
  }
};
</script>
