<template>
  <div class="container mt-5">
    <h2>Blagajna</h2>
    <form @submit.prevent="submitOrder">
      
      <h3>Podaci o dostavi</h3>
      
      <div class="mb-3">
        <label for="firstName" class="form-label">Ime</label>
        <input type="text" v-model="order.firstName" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="lastName" class="form-label">Prezime</label>
        <input type="text" v-model="order.lastName" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="phoneNumber" class="form-label">Broj telefona</label>
        <input type="tel" v-model="order.phoneNumber" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="address1" class="form-label">Adresa</label>
        <input type="text" v-model="order.address1" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="address2" class="form-label">Dodatne informacije o adresi</label>
        <input type="text" v-model="order.address2" class="form-control" />
      </div>
      
      <div class="mb-3">
        <label for="city" class="form-label">Grad</label>
        <input type="text" v-model="order.city" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="country" class="form-label">Država</label>
        <input type="text" v-model="order.country" class="form-control" required />
      </div>

      <h3>Podaci o plaćanju</h3>
      
      <div class="mb-3">
        <label for="cardNumber" class="form-label">Broj kreditne kartice</label>
        <input type="text" v-model="order.cardNumber" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="cvv" class="form-label">CVV/CVC</label>
        <input type="text" v-model="order.cvv" class="form-control" required maxlength="3" />
      </div>
      
      <div class="mb-3">
        <label for="expirationDate" class="form-label">Datum isteka</label>
        <input type="month" v-model="order.expirationDate" class="form-control" required />
      </div>
      
      <div class="mb-3">
        <label for="nameOnCard" class="form-label">Ime na kartici</label>
        <input type="text" v-model="order.nameOnCard" class="form-control" required />
      </div>

      <button type="submit" class="btn btn-primary">Potvrdi narudžbu</button>
    </form>
  </div>
</template>

<script>
import { useCartStore } from '../store/cart';
import orderService from '../services/orderService';

export default {
  name: 'CheckoutComponent',
  data() {
    return {
      order: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        cardNumber: '',
        cvv: '',
        expirationDate: '',
        nameOnCard: '',
        items: [],
        totalAmount: 0,
      },
    };
  },
  methods: {
    async submitOrder() {
      try {
        const cartStore = useCartStore();
        this.order.items = JSON.parse(localStorage.getItem('cartItems')) || [];
        this.order.totalAmount = cartStore.cartTotal;

        await orderService.createOrder(this.order);
        localStorage.removeItem('cartItems');
        this.$router.push('/');
      } catch (error) {
        alert('Neuspješno potvrđivanje narudžbe: ' + error.message);
        console.log("ERROR", error);
      }
    },
  },
};
</script>
