<template>
  <div class="container mt-5">
    <h2>Administratorska Ploča</h2>
    <div class="list-group">
      <router-link class="list-group-item list-group-item-action" to="/admin/products">Upravljanje Proizvodima</router-link>
      <router-link class="list-group-item list-group-item-action" to="/admin/categories">Upravljanje Kategorijama</router-link>
      <router-link class="list-group-item list-group-item-action" to="/admin/orders">Upravljanje Narudžbama</router-link>
      <router-link class="list-group-item list-group-item-action" to="/admin/users">Upravljanje Korisnicima</router-link>
      <router-link class="list-group-item list-group-item-action" to="/admin/reviews">Upravljanje Recenzijama</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminDashboard',
};
</script>
