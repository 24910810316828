<template>
  <div class="container mt-5">
    <BannerCarousel />
    <ProductList :filteredProducts="products" />
    <nav aria-label="Page navigation" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchProducts(currentPage - 1)" :disabled="currentPage === 1">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchProducts(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchProducts(currentPage + 1)" :disabled="currentPage === totalPages">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import productService from '../services/productService';
import ProductList from '../components/ProductList.vue';
import BannerCarousel from '../components/BannerCarousel.vue';

export default {
  name: 'HomeComponent',
  components: {
    ProductList,
    BannerCarousel,
  },
  data() {
    return {
      products: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  async created() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts(page = 1) {
      try {
        const response = await productService.getAllProducts(page, 9);
        this.products = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
  },
};
</script>
