<template>
  <div class="container mt-5">
    <h2>Resetirajte lozinku</h2>
    <form @submit.prevent="resetPassword">
      <div class="mb-3">
        <label for="newPassword" class="form-label">Nova lozinka</label>
        <input type="password" v-model="newPassword" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="confirmPassword" class="form-label">Potvrdite lozinku</label>
        <input type="password" v-model="confirmPassword" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary">Resetiraj lozinku</button>
    </form>
  </div>
</template>

<script>
import authService from '@/services/authService';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'PasswordResetCreateNewPass',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert('Lozinke se ne podudaraju!');
        return;
      }

      try {
        const resetToken = this.route.params.resetToken;
        await authService.resetPassword(resetToken, this.newPassword);
        alert('Lozinka je uspješno resetirana. Sada se možete prijaviti s novom lozinkom.');
        this.router.push('/login');
      } catch (error) {
        alert('Neuspješno resetiranje lozinke');
      }
    },
  },
};
</script>
