<template>
  <div id="app">
    <NavBar />
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import { useCartStore } from './store/cart';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  created() {
    const cartStore = useCartStore();
    cartStore.loadCart();
  },
};
</script>

<style>
@import './styles/main.css';
</style>
