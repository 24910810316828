<template>
    <div>
      <Search :query="$route.query" />
    </div>
  </template>
  
  <script>
  import Search from '../components/Search.vue';
  
  export default {
    name: 'SearchView',
    components: {
      Search,
    },
  };
  </script>
  