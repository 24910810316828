import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import ProductList from '../views/ProductList.vue';
import ProductDetail from '../views/ProductDetail.vue';
import Cart from '../views/Cart.vue';
import Checkout from '../views/Checkout.vue';
import Admin from '../views/Admin.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Search from '../views/Search.vue';
import ProductForm from '../components/Admin/Product/ProductForm.vue';
import ProductUpdate from '../components/Admin/Product/ProductUpdate.vue';
import ProductListAdmin from '../components/Admin/Product/ProductList.vue';
import Categories from '../views/Categories.vue';
import CategoryForm from '../components/Admin/Category/CategoryForm.vue';
import CategoryUpdate from '../components/Admin/Category/CategoryUpdate.vue';
import CategoryList from '../components/Admin/Category/CategoryList.vue';
import OrderList from '../components/Admin/Order/OrderList.vue';
import OrderUpdate from '../components/Admin/Order/OrderUpdate.vue';
import UserForm from '../components/Admin/User/UserForm.vue';
import UserUpdate from '../components/Admin/User/UserUpdate.vue';
import UserList from '../components/Admin/User/UserList.vue';
import PasswordReset from '../views/PasswordReset.vue';
import PasswordResetCreateNewPass from '../views/PasswordResetCreateNewPass.vue';
import AccountActivation from '../views/AccountActivation.vue';
import ReviewList from '../components/Admin/Reviews/ReviewList.vue';
import AccountPage from '../views/AccountPage.vue';
import OrderOverview from '../views/OrderOverview.vue';
import { useStore } from '../store/index';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/products', name: 'ProductList', component: ProductList },
  { path: '/products/:id', name: 'ProductDetail', component: ProductDetail },
  { path: '/cart', name: 'Cart', component: Cart },
  { path: '/checkout', name: 'Checkout', component: Checkout },
  { path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/search', name: 'Search', component: Search },
  { path: '/categories/:id', name: 'Categories', component: Categories },

  { path: '/admin/products', name: 'ProductListAdmin', component: ProductListAdmin, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/products/add', name: 'ProductForm', component: ProductForm, meta: { requiresAuthentication: true, requiresAuthorization: true }},
  { path: '/admin/products/:id', name: 'ProductUpdate', component: ProductUpdate, meta: { requiresAuthentication: true, requiresAuthorization: true } },

  { path: '/admin/categories', name: 'CategoryList', component: CategoryList, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/category/add', name: 'CategoryForm', component: CategoryForm, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/category/:id', name: 'CategoryUpdate', component: CategoryUpdate, meta: { requiresAuthentication: true, requiresAuthorization: true } },

  { path: '/admin/orders', name: 'OrderList', component: OrderList, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/orders/:id', name: 'OrderUpdate', component: OrderUpdate, meta: { requiresAuthentication: true, requiresAuthorization: true } },

  { path: '/admin/users', name: 'UserList', component: UserList, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/users/add', name: 'UserForm', component: UserForm, meta: { requiresAuthentication: true, requiresAuthorization: true } },
  { path: '/admin/users/:id', name: 'UserUpdate', component: UserUpdate, meta: { requiresAuthentication: true, requiresAuthorization: true } },

  { path: '/admin/reviews', name: 'ReviewList', component: ReviewList, meta: { requiresAuthentication: true, requiresAuthorization: true } },

  { path: '/password-reset', name: 'PasswordReset', component: PasswordReset },
  { path: '/password-reset/:resetToken', name: 'PasswordResetCreateNewPass', component: PasswordResetCreateNewPass },
  { path: '/activate/:activationCode', name: 'AccountActivation', component: AccountActivation },

  { path: '/user/account', name: 'AccountPage', component: AccountPage, meta: { requiresAuthentication: true } },
  { path: '/user/orders', name: 'OrderOverview', component: OrderOverview, meta: { requiresAuthentication: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuthentication = to.matched.some(record => record.meta.requiresAuthentication);
  const requiresAuthorization = to.matched.some(record => record.meta.requiresAuthorization);
  const store = useStore();
  store.loadUser();

  if (requiresAuthentication && !store.isAuthenticated) {
    next('/login');
  } else if (requiresAuthorization && !store.isAuthorized) {
    next('/');
  } else {
    next();
  }
});

export default router;
