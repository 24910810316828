<template>
    <div>
      <Checkout />
    </div>
  </template>
  
  <script>
  import Checkout from '../components/Checkout.vue';
  
  export default {
    name: 'CheckoutView',
    components: {
      Checkout,
    },
  };
  </script>
  