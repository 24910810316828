<template>
  <div class="container mt-5">
    <h2>Košarica</h2>
    <div class="row mb-2 d-none d-md-flex">
      <div class="col-md-2"><strong>Slika</strong></div>
      <div class="col-md-4"><strong>Proizvod</strong></div>
      <div class="col-md-3"><strong>Količina</strong></div>
      <div class="col-md-2"><strong>Ukupno</strong></div>
      <div class="col-md-1"><strong>Uredi/Obriši</strong></div>
    </div>
    <div v-for="item in cartItems" :key="item.product.id" class="border-bottom my-2">
      <div class="row my-2">
        <div class="col-12 col-md-2 d-flex flex-column align-items-center">
          <img :src="item.product.images[0]" alt="Slika proizvoda" class="img-thumbnail mb-2" style="width: 100%">
        </div>
        <div class="col-12 col-md-4 d-flex align-items-center">
          <h5 class="card-title mb-0">{{ item.product.name }}</h5>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
          <div class="input-group">
            <button
              class="btn btn-outline-secondary me-2"
              @click="decrementQuantity(item.product.id)"
              v-if="item.quantity > 1"
            >-</button>
            <input type="text" class="form-control me-2" :value="item.quantity" readonly style="width: 60px;">
            <button class="btn btn-outline-secondary" @click="incrementQuantity(item.product.id)">+</button>
          </div>
        </div>
        <div class="col-12 col-md-2 d-flex align-items-center">
          <p class="card-text mb-0">{{ formatPrice(item.product.price * item.quantity) }} KM</p>
        </div>
        <div class="col-12 col-md-1 d-flex align-items-center">
          <button class="btn btn-danger" @click="removeFromCart(item.product.id)">Ukloni</button>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h3>Ukupno: {{ formatPrice(cartTotal) }} KM</h3>
      <button class="btn btn-success" @click="proceedToCheckout" :disabled="cartItems.length === 0">Nastavi na blagajnu</button>
    </div>
  </div>
</template>


<script>
import { useCartStore } from '../store/cart';
import { useStore } from '../store'; 
import { useRouter } from 'vue-router'; 
import { mapActions, mapState } from 'pinia';

export default {
  name: 'CartComponent',
  setup() {
    const store = useStore();
    const router = useRouter();

    const proceedToCheckout = () => {
      if (!store.isAuthenticated) {
        router.push('/login');
      } else {
        router.push('/checkout');
      }
    };

    return {
      store,
      router,
      proceedToCheckout,
    };
  },
  computed: {
    ...mapState(useCartStore, ['cartItems', 'cartTotal']),
  },
  methods: {
    ...mapActions(useCartStore, ['removeFromCart', 'incrementQuantity', 'decrementQuantity']),
    formatPrice(value) {
      if (typeof value === 'number') {
        return value.toFixed(0);
      }
      return parseFloat(value).toFixed(0);
    },
  },
};
</script>
