<template>
    <div>
      <Categories :categoryId="$route.params.id" />
    </div>
  </template>
  
  <script>
  import Categories from '../components/Categories.vue';
  
  export default {
    name: 'CategoriesView',
    components: {
      Categories,
    },
  };
  </script>
  