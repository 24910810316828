<template>
  <div class="container mt-5">
    <h2>Recenzije</h2>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">ID Recenzenta</th>
          <th scope="col">ID Proizvoda</th>
          <th scope="col">Ocjena</th>
          <th scope="col">Komentar</th>
          <th scope="col">Obriši</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(review, i) in reviews" :key="i">
          <th scope="row">{{ review.id }}</th>
          <td>{{ review.userId }}</td>
          <td>{{ review.productId }}</td>
          <td>{{ review.rating }}</td>
          <td>{{ review.comment }}</td>
          <td>
            <div class="d-inline ms-2">
              <i class="bi bi-trash" @click="deleteReview(review.id)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav v-if="totalPages > 1" aria-label="Page navigation" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchReviews(currentPage - 1)">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchReviews(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchReviews(currentPage + 1)">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import reviewService from '../../../services/reviewService';

export default {
  name: 'ReviewList',
  data() {
    return {
      reviews: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    async deleteReview(id) {
      try {
        await reviewService.deleteReview(id);
        this.fetchReviews(this.currentPage);
      } catch (error) {
        console.error('Error deleting review:', error);
      }
    },
    async fetchReviews(page = 1) {
      try {
        const response = await reviewService.getAllReviews(page, 20);
        this.reviews = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    },
  },
  async created() {
    this.fetchReviews();
  }
};
</script>
