<template>
  <div class="container mt-5">
    <h2>Narudžbe</h2>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Korisnički ID</th>
          <th scope="col">Ukupni iznos</th>
          <th scope="col">Status</th>
          <th scope="col">Kreirano</th>
          <th scope="col">Uredi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order) in orders" :key="order.id">
          <th scope="row">{{ order.id }}</th>
          <td>{{ order.userId }}</td>
          <td>{{ order.totalAmount }}</td>
          <td>{{ order.status }}</td>
          <td>{{ order.createdAt }}</td>
          <td>
            <div class="d-inline me-2">
              <i class="bi bi-pencil" @click="editOrder(order.id)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav aria-label="Page navigation" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchOrders(currentPage - 1)"
            :disabled="currentPage === 1">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchOrders(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchOrders(currentPage + 1)"
            :disabled="currentPage === totalPages">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import orderService from '../../../services/orderService';

export default {
  name: 'OrderList',
  data() {
    return {
      orders: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    editOrder(id) {
      this.$router.push({ name: "OrderUpdate", params: { id: id } });
    },
    async fetchOrders(page = 1) {
      try {
        const response = await orderService.getAllOrders(page, 20);
        this.orders = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        alert('Failed to fetch orders: ' + error.message);
      }
    },
  },
  async created() {
    this.fetchOrders();
  }
};
</script>
