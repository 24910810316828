import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/products/';

const getAllProducts = (page = 1, limit = 9) => {
  return axios.get(`${API_URL}?page=${page}&limit=${limit}`);
};

const getProductById = (id) => {
  return axios.get(`${API_URL}${id}`);
};

const getByCategoryId = (id) => {
  return axios.get(`${API_URL}search?category=${id}`);
};

const createProduct = (product) => {
  return axios.post(API_URL, product, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const updateProduct = (id, product) => {
  return axios.put(`${API_URL}${id}`, product, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const deleteProduct = (id) => {
  return axios.delete(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const searchProducts = (query) => {
  const params = new URLSearchParams();
  if (query.name) params.append('name', query.name);
  if (query.category) params.append('category', query.category);
  if (query.minPrice) params.append('minPrice', query.minPrice);
  if (query.maxPrice) params.append('maxPrice', query.maxPrice);

  return axios.get(`${API_URL}search?${params.toString()}`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching search results:', error);
      return [];
    });
};

const uploadImages = (formData) => {
  return axios.post('https://zavrsni-rad-backend.ludereterram.com/api/images/upload', formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default {
  getAllProducts,
  getProductById,
  getByCategoryId,
  createProduct,
  updateProduct,
  deleteProduct,
  searchProducts,
  uploadImages,
};
