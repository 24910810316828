<template>
  <div class="container mt-5">
    <h2>Kategorija: {{ categoryName }}</h2>
    <ProductList :filteredProducts="products" />
  </div>
</template>

<script>
import productService from '../services/productService';
import categoryService from '../services/categoryService';
import ProductList from '../components/ProductList.vue';

export default {
  name: 'CategoriesComponent',
  components: {
    ProductList,
  },
  data() {
    return {
      products: [],
      categoryName: '',
    };
  },
  async created() {
    await this.fetchCategoryAndProducts();
  },
  watch: {
    '$route.params.id': 'fetchCategoryAndProducts',
  },
  methods: {
    async fetchCategoryAndProducts() {
      const categoryId = this.$route.params.id;

      try {
        const categoryResponse = await categoryService.getCategoryById(categoryId);
        this.categoryName = categoryResponse.data.name;

        const productResponse = await productService.getByCategoryId(categoryId);
        this.products = productResponse.data;
      } catch (error) {
        console.error('Error fetching category or products:', error);
      }
    }
  }
};
</script>
