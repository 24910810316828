<template>
    <div>
      <ProductDetail :productId="$route.params.id" />
    </div>
  </template>
  
  <script>
  import ProductDetail from '../components/ProductDetail.vue';
  
  export default {
    name: 'ProductDetailView',
    components: {
      ProductDetail,
    },
  };
  </script>
  