<template>
  <div class="container mt-5">
    <h1>Registracija</h1>
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <form @submit.prevent="register">
      <div class="form-group">
        <label for="username">Korisničko ime</label>
        <input type="text" v-model="username" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" v-model="email" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="password">Lozinka</label>
        <input type="password" v-model="password" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="firstName">Ime</label>
        <input type="text" v-model="firstName" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="lastName">Prezime</label>
        <input type="text" v-model="lastName" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary">Registriraj se</button>
    </form>
  </div>
</template>

<script>
import authService from '../services/authService';

export default {
  name: 'RegisterView',
  data() {
    return {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      errorMessage: '',
    };
  },
  methods: {
    async register() {
      try {
        this.errorMessage = ''; 
        await authService.register({
          username: this.username,
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
        });
        this.$router.push('/login');
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>
