<template>
  <div class="container mt-5">
    <h2>Korisnici</h2>
    <router-link to="/admin/users/add">
      <btn class="btn btn-success">Dodaj</btn>
    </router-link>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Ime</th>
          <th scope="col">Prezime</th>
          <th scope="col">Korisničko ime</th>
          <th scope="col">Uloga</th>
          <th scope="col">Email</th>
          <th scope="col">Uredi/Obriši</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, i) in users" :key="i">
          <th scope="row">{{ user.id }}</th>
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.role }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div class="d-inline me-2"><i class="bi bi-pencil" @click="editUser(user.id)"></i></div>
            <div class="d-inline ms-2"><i class="bi bi-trash" @click="deleteUser(user.id)"></i></div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav aria-label="Navigacija po stranicama" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Prethodna" @click.prevent="fetchUsers(currentPage - 1)"
            :disabled="currentPage === 1">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchUsers(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Sljedeća" @click.prevent="fetchUsers(currentPage + 1)"
            :disabled="currentPage === totalPages">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import userService from '../../../services/userService';

export default {
  name: 'UserList',
  data() {
    return {
      users: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    async fetchUsers(page = 1) {
      try {
        const response = await userService.getAllUsers(page, 10);
        this.users = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async deleteUser(id) {
      try {
        await userService.deleteUser(id);
        this.fetchUsers(this.currentPage);
      } catch (error) {
        alert('Neuspješno brisanje korisnika: ' + error.message);
      }
    },
    editUser(id) {
      this.$router.push({ name: "UserUpdate", params: { id } });
    }
  },
  async created() {
    this.fetchUsers();
  }
};
</script>
