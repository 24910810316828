<template>
  <div class="container mt-5">
    <h2>Resetiranje lozinke</h2>
    <form @submit.prevent="requestPasswordReset">
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" v-model="email" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-primary">Zatraži resetiranje lozinke</button>
    </form>
  </div>
</template>

<script>
import authService from '@/services/authService';

export default {
  name: 'PasswordReset',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async requestPasswordReset() {
      try {
        await authService.requestPasswordReset(this.email);
        alert('Ako račun s tim emailom postoji, link za resetiranje lozinke je poslan.');
      } catch (error) {
        alert('Neuspješan zahtjev za resetiranje lozinke');
      }
    },
  },
};
</script>
