import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {
  state: () => ({
    cartItems: [],
  }),
  getters: {
    cartTotal(state) {
      return state.cartItems.reduce((total, item) => total + item.product.price * item.quantity, 0);
    },
  },
  actions: {
    addToCart(product) {
      const existingItem = this.cartItems.find(item => item.product.id === product.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        this.cartItems.push({ product, quantity: 1 });
      }
      this.saveCart();
    },
    removeFromCart(productId) {
      this.cartItems = this.cartItems.filter(item => item.product.id !== productId);
      this.saveCart();
    },
    incrementQuantity(productId) {
      const item = this.cartItems.find(item => item.product.id === productId);
      if (item) {
        item.quantity += 1;
        this.saveCart();
      }
    },
    decrementQuantity(productId) {
      const item = this.cartItems.find(item => item.product.id === productId);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
        this.saveCart();
      }
    },
    saveCart() {
      localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    },
    loadCart() {
      const savedCart = localStorage.getItem('cartItems');
      if (savedCart) {
        this.cartItems = JSON.parse(savedCart);
      }
    },
    clearCart() {
      this.cartItems = [];
      this.saveCart();
    },
  },
});
