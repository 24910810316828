<template>
  <div id="bannerCarousel" class="carousel slide mb-5" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button 
        v-for="(banner, index) in banners" 
        :key="index" 
        type="button" 
        :data-bs-target="'#bannerCarousel'"
        :data-bs-slide-to="index" 
        :class="{ active: index === 0 }" 
        aria-current="index === 0 ? 'true' : 'false'" 
        :aria-label="'Slide ' + (index + 1)">
      </button>
    </div>
    <div class="carousel-inner">
      <div 
        v-for="(banner, index) in banners" 
        :key="index" 
        :class="['carousel-item', { active: index === 0 }]"
      >
        <a :href="banner.link">
          <img :src="banner.imageUrl" class="d-block w-100" :alt="'Slide ' + (index + 1)">
        </a>
      </div>
    </div>
    <button class="carousel-control-prev custom-carousel-btn" type="button" data-bs-target="#bannerCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden"></span>
    </button>
    <button class="carousel-control-next custom-carousel-btn" type="button" data-bs-target="#bannerCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BannerCarousel',
  data() {
    return {
      banners: [
        {
          imageUrl: require('@/assets/images/banner_images/galaxy_fold.jpeg'),
          link: '/categories/1',
        },
        {
          imageUrl: require('@/assets/images/banner_images/aoc_monitor.jpeg'),
          link: '/categories/5',
        },
        {
          imageUrl: require('@/assets/images/banner_images/galaxy_buds.jpeg'),
          link: '/categories/2',
        },
        {
          imageUrl: require('@/assets/images/banner_images/philips_monitor.jpeg'),
          link: '/categories/5',
        },
        {
          imageUrl: require('@/assets/images/banner_images/galaxy_watch.jpeg'),
          link: '/categories/3',
        },
      ],
    };
  },
};
</script>

<style scoped>
.carousel-item {
  height: 400px;
}

.carousel-item img {
  object-fit: cover;
  height: 100%;
}

.custom-carousel-btn {
  width: 50px;
  filter: invert(1);
}
.carousel-indicators button {
  background-color: black;
}

.carousel-indicators .active {
  background-color: white;
  filter: invert(1);
}

@media (max-width: 768px) {
  .carousel-item {
    height: 250px;
  }

  .carousel-item img {
    object-fit: contain;
  }

  .custom-carousel-btn {
    width: 30px;
  }

  .carousel-indicators button {
    width: 10px;
    height: 10px;
  }
}
</style>
