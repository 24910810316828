<template>
    <div>
      <AdminDashboard />
    </div>
  </template>
  
  <script>
  import AdminDashboard from '../components/Admin/AdminDashboard.vue';
  
  export default {
    name: 'AdminView',
    components: {
      AdminDashboard,
    },
  };
  </script>
  