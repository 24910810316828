<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Završni rad</a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item" v-for="category in categories" :key="category.id">
            <router-link class="nav-link" :to="'/categories/' + category.id">{{ category.name }}</router-link>
          </li>
        </ul>

        <div class="d-flex align-items-center">
          <router-link class="nav-link m-2" to="/search" aria-label="Search">
            <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none">
              <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>

          <div class="position-relative cart-icon-container">
            <router-link class="nav-link m-2" to="/cart" aria-label="View Cart">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none">
                <path d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </router-link>
            <span v-if="cartItemCount > 0" class="badge badge-pill bg-danger position-absolute top-0 start-100 translate-middle cart-badge">{{ cartItemCount }}</span>
          </div>

          <div class="dropdown">
            <button class="btn dropdown-toggle p-0 m-2" type="button" id="accountDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none">
                <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="accountDropdown">
              <li v-if="!isAuthenticated">
                <router-link class="dropdown-item" to="/login">Prijava</router-link>
              </li>
              <li v-if="!isAuthenticated">
                <router-link class="dropdown-item" to="/register">Registracija</router-link>
              </li>
              <li v-if="isAuthenticated && isAuthorized">
                <router-link class="dropdown-item" to="/admin">Administratorska ploča</router-link>
              </li>
              <li v-if="isAuthenticated">
                <router-link class="dropdown-item" to="/user/account">Korisnički podaci</router-link>
              </li>
              <li v-if="isAuthenticated">
                <router-link class="dropdown-item" to="/user/orders">Narudžbe</router-link>
              </li>
              <li v-if="isAuthenticated">
                <a class="dropdown-item" href="#" @click="logoutUser">Odjava</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useStore } from '../store/index';
import { useCartStore } from '../store/cart';
import { mapState } from 'pinia';
import categoryService from '../services/categoryService';

export default {
  name: 'NavBar',
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    ...mapState(useStore, ['isAuthenticated', 'isAuthorized', 'logout']),
    cartItemCount() {
      const cartStore = useCartStore();
      return cartStore.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
  },
  methods: {
    logoutUser() {
      this.logout();
      this.$router.push('/');
    },
    async fetchCategories() {
      try {
        const response = await categoryService.getAllCategories();
        this.categories = response.data.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
  },
  async created() {
    await this.fetchCategories();
  },
};
</script>

<style scoped>
.navbar {
  margin-bottom: 20px;
}

.cart-icon-container {
  position: relative;
}

.cart-badge {
  position: absolute;
  top: 12px !important;
  left: 42px !important;
  background-color: #dc3545;
  color: white;
  font-size: 0.75rem;
  padding: 0.2em 0.4em;
}

</style>
