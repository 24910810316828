<template>
  <div class="container mt-5">
    <h2>Kategorije</h2>
    <router-link to="/admin/category/add">
      <btn class="btn btn-success">Dodaj</btn>
    </router-link>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Naziv</th>
          <th scope="col">Opis</th>
          <th scope="col">Uredi/Obriši</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(category, i) in categories" :key="i">
          <th scope="row">{{ category.id }}</th>
          <td>{{ category.name }}</td>
          <td>{{ category.description }}</td>
          <td>
            <div class="d-inline me-2"><i class="bi bi-pencil" @click="editCategory(category.id)"></i></div>
            <div class="d-inline ms-2"><i class="bi bi-trash" @click="deleteCategory(category.id)"></i></div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav aria-label="Page navigation" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchCategories(currentPage - 1)"
            :disabled="currentPage === 1">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchCategories(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchCategories(currentPage + 1)"
            :disabled="currentPage === totalPages">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import categoryService from '../../../services/categoryService';

export default {
  name: 'CategoryList',
  data() {
    return {
      categories: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    async fetchCategories(page = 1) {
      try {
        const response = await categoryService.getAllCategories(page, 10);
        this.categories = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async deleteCategory(id) {
      try {
        await categoryService.deleteCategory(id);
        this.fetchCategories(this.currentPage);
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    },
    editCategory(id) {
      this.$router.push({ name: "CategoryUpdate", params: { id } });
    }
  },
  async created() {
    this.fetchCategories();
  }
};
</script>
