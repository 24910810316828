<template>
  <div class="container mt-5">
    <h2>Dodaj korisnika</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="username" class="form-label">Korisničko ime</label>
        <input type="text" v-model="user.username" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" v-model="user.email" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Lozinka</label>
        <input type="password" v-model="user.password" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="firstName" class="form-label">Ime</label>
        <input type="text" v-model="user.firstName" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="lastName" class="form-label">Prezime</label>
        <input type="text" v-model="user.lastName" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="role" class="form-label">Uloga</label>
        <select v-model="user.role" class="form-select">
          <option value="customer">Korisnik</option>
          <option value="admin">Administrator</option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary">Spremi</button>
    </form>
  </div>
</template>

<script>
import userService from '../../../services/userService';

export default {
  name: 'UserForm',
  data() {
    return {
      user: {
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        role: 'customer'
      }
    };
  },
  methods: {
    async submitForm() {
      try {
        await userService.updateUser(this.user.id, this.user);
        this.$router.push('/admin/users');
      } catch (error) {
        alert('Neuspješno slanje forme');
      }
    }
  },
  async created() {
    const id = this.$route.params.id;
    if (id) {
      this.user = await userService.getUserById(id);
    }
  }
};
</script>
