import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/categories/';

const getAllCategories = (page = 1, limit = 10) => {
  return axios.get(`${API_URL}?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const createCategory = (category) => {
  return axios.post(API_URL, category, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const getCategoryById = (id) => {
  return axios.get(`${API_URL}${id}`);
};

const updateCategory = (id, category) => {
  return axios.put(`${API_URL}${id}`, category, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const deleteCategory = (id) => {
  return axios.delete(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export default {
  getAllCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
};
