import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/orders/';

const getAllOrders = (page = 1, limit = 10) => {
  return axios.get(`${API_URL}?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const getOrderById = (id) => {
  return axios.get(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const createOrder = (order) => {
  return axios
    .post(API_URL, order, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      localStorage.removeItem('cart');
      return response;
    })
    .catch((error) => {
      console.error('Error creating order:', error);
      throw error;
    });
};


const updateOrder = (id, order) => {
  return axios.put(`${API_URL}${id}`, order, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const deleteOrder = (id) => {
  return axios.delete(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const getOrdersByCustomerId = (customerId, page = 1, limit = 10) => {
  return axios.get(`${API_URL}customer/${customerId}?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export default {
  getAllOrders,
  getOrderById,
  createOrder,
  updateOrder,
  deleteOrder,
  getOrdersByCustomerId,
};
