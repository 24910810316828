<template>
  <div class="container mt-5">
    <h2>Novi proizvod</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="name" class="form-label">Naziv</label>
        <input type="text" v-model="product.name" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Opis</label>
        <input type="text" v-model="product.description" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="price" class="form-label">Cijena</label>
        <input type="number" v-model="product.price" step="0.01" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="category" class="form-label">Kategorija</label>
        <select v-model="product.categoryId" id="categorySelect" class="form-control">
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="stock" class="form-label">Zaliha</label>
        <input type="number" v-model="product.stock" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="image" class="form-label">Dodaj sliku</label>
        <input type="file" ref="imageFile" @change="onImageChange" multiple class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary">Spremi</button>
    </form>
  </div>
</template>

<script>
import productService from '../../../services/productService';
import categoryService from '../../../services/categoryService';

export default {
  name: 'ProductForm',
  data() {
    return {
      product: {
        name: '',
        description: '',
        price: 0,
        categoryId: 0,
        stock: 0,
      },
      categories: [],
      selectedFiles: []
    };
  },
  methods: {
    async submitForm() {
      try {
        const createdProduct = await productService.createProduct(this.product);
        const productId = createdProduct.data.id;

        if (this.selectedFiles.length > 0) {
          const formData = new FormData();
          this.selectedFiles.forEach(file => {
            formData.append('images', file);
          });
          formData.append('productId', productId);

          await productService.uploadImages(formData);
        }

        this.$router.push('/admin/products');
      } catch (error) {
        alert('Neuspješno slanje forme');
      }
    },
    onImageChange(event) {
      this.selectedFiles = Array.from(event.target.files);
    },
    async getCategories() {
      const response = await categoryService.getAllCategories();
      this.categories = response.data.data;
    }
  },
  async mounted() {
    await this.getCategories();
  }
};
</script>
