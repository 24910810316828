<template>
  <div class="container mt-5">
    <h2>Proizvodi</h2>
    <router-link to="/admin/products/add">
      <btn class="btn btn-success">Dodaj</btn>
    </router-link>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Naziv</th>
          <th scope="col">Cijena</th>
          <th scope="col">Zaliha</th>
          <th scope="col">Opis</th>
          <th scope="col">Uredi/Obriši</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product, i) in products" :key="i">
          <th scope="row">{{ product.id }}</th>
          <td>{{ product.name }}</td>
          <td>{{ product.price }}</td>
          <td>{{ product.stock }}</td>
          <td>{{ product.description }}</td>
          <td>
            <div class="d-inline me-2">
              <i class="bi bi-pencil" @click="editProduct(product.id)"></i>
            </div>
            <div class="d-inline ms-2">
              <i class="bi bi-trash" @click="deleteProduct(product.id)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav v-if="totalPages > 1" aria-label="Page navigation" class="d-flex justify-content-center mt-4">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchProducts(currentPage - 1)"
            :disabled="currentPage === 1">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="fetchProducts(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchProducts(currentPage + 1)"
            :disabled="currentPage === totalPages">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import productService from '../../../services/productService';

export default {
  name: 'ProductList',
  data() {
    return {
      products: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    async deleteProduct(id) {
      await productService.deleteProduct(id);
      this.fetchProducts(this.currentPage);
    },
    editProduct(id) {
      this.$router.push({ name: "ProductUpdate", params: { id: id } });
    },
    async fetchProducts(page = 1) {
      try {
        const response = await productService.getAllProducts(page, 20);
        this.products = response.data.data;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
  },
  async created() {
    this.fetchProducts();
  }
};
</script>
