<template>
  <div class="container mt-5">
    <h2>Uredi narudžbu</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="userId" class="form-label">Korisnički ID</label>
        <input type="number" v-model="order.userId" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="totalAmount" class="form-label">Ukupan iznos</label>
        <input type="number" v-model="order.totalAmount" step="0.01" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">Status</label>
        <select v-model="order.status" class="form-control" required>
          <option value="pending">Pending</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="canceled">Canceled</option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary">Spremi</button>
    </form>
  </div>
</template>

<script>
import orderService from '../../../services/orderService';

export default {
  name: 'OrderUpdate',
  data() {
    return {
      order: {
        userId: 0,
        totalAmount: 0,
        status: 'pending',
      }
    };
  },
  methods: {
    async submitForm() {
      try {
        await orderService.updateOrder(this.order.id, this.order);
        this.$router.push('/admin/orders');
      } catch (error) {
        console.log('Failed to fetch the order: ' + error.message);
      }
    }
  },
  async created() {
    try {
      const id = this.$route.params.id;
      const response = await orderService.getOrderById(id);
      this.order = response.data;
    } catch (error) {
      alert('Neuspješno slanje forme');
    }
  }
};
</script>
