import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/reviews/';

const getReviewsByProductId = (productId) => {
  return axios.get(`${API_URL}product/${productId}`);
};

const getAllReviews = (page = 1, limit = 10) => {
  return axios.get(`${API_URL}?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const createReview = (reviewData) => {
  return axios.post(API_URL, reviewData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const deleteReview = (id) => {
  return axios.delete(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export default {
  getReviewsByProductId,
  getAllReviews,
  createReview,
  deleteReview,
};
