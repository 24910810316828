<template>
  <div class="container mt-5">
    <div class="account-card p-3 border rounded" v-if="user">
      <h4>Korisnički podaci</h4>
      <div class="account-details">
        <div class="item-detail mb-2">
          <p><strong>Korisničko ime:</strong> {{ user.username }}</p>
          <p><strong>Email:</strong> {{ user.email }}</p>
          <p><strong>Ime:</strong> {{ user.firstName }}</p>
          <p><strong>Prezime:</strong> {{ user.lastName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '../services/userService'; 
import { useStore } from '../store/index'; 

export default {
  name: 'AccountPage',
  data() {
    return {
      user: null,
    };
  },
  async created() {
    const store = useStore(); 
    const userId = store.user.id; 

    try {
      const response = await userService.getUserById(userId);
      this.user = response.data; 
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
}

.account-card {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 5px;
}

.account-card h4 {
  border-bottom: 2px solid gray;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.item-detail {
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-left: 2px solid gray;
  border-radius: 5px;
  margin-bottom: 1rem;
}
</style>
