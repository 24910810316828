import { createPinia, defineStore } from 'pinia';
import authService from '../services/authService';
import { jwtDecode } from "jwt-decode";
import { useCartStore } from "./cart"

const useStore = defineStore('main', {
  state: () => ({
    user: null,
    token: localStorage.getItem('token') || '',
  }),
  actions: {
    async login(user) {
      const response = await authService.login(user);
      const payload = jwtDecode(response.token, { payload: true });

      this.user = { id: payload.id, role: payload.role };
      this.token = response.token;
      localStorage.setItem('user', JSON.stringify(this.user));

    },
    logout() {
      authService.logout();
      this.user = null;
      this.token = '';
      useCartStore().clearCart()
    },
    loadUser() {
      const savedUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');
    
      if (token) {
        const payload = jwtDecode(token, { payload: true });
        const currentTime = Math.floor(Date.now() / 1000);
    
        if (payload.exp && payload.exp < currentTime) {
          return;
        }
    
        if (savedUser) {
          this.user = JSON.parse(savedUser);
        }
      }
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    isAuthorized: state => state.user && state.user.role === "admin",
  }
});

const pinia = createPinia();

export { pinia, useStore };
