<template>
  <div class="container mt-5">
    <h2>Nova kategorija</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="name" class="form-label">Naziv</label>
        <input type="text" v-model="category.name" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Opis</label>
        <input type="text" v-model="category.description" class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary">Spremi</button>
    </form>
  </div>
</template>

<script>
import categoryService from '../../../services/categoryService';

export default {
  name: 'CategoryForm',
  data() {
    return {
      category: {
        name: '',
        description: ''
      }
    };
  },
  methods: {
    async submitForm() {
      try {
        await categoryService.createCategory(this.category);
        this.$router.push('/admin/categories');
      } catch (error) {
        alert('Neuspješno slanje forme');
      }
    }
  }
};
</script>