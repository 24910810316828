<template>
  <div class="container mt-5">
    <h2>Narudžbe</h2>
    <div v-for="order in orders" :key="order.id" class="order-card mb-5 p-3 border rounded">
      <h4>Informacije o narudžbi</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <p><strong>Broj narudžbe:</strong> {{ order.id }}</p>
          <p><strong>Datum narudžbe:</strong> {{ new Date(order.createdAt).toLocaleDateString() }}</p>
        </div>
        <div class="col-md-6">
          <p><strong>Status:</strong> {{ order.status }}</p>
          <p><strong>Ukupan iznos:</strong> ${{ order.totalAmount }}</p>
        </div>
      </div>

      <h5>Detalji dostave</h5>
      <div class="row mb-3">
        <div class="col-md-6">
          <p><strong>Ime:</strong> {{ order.firstName }}</p>
          <p><strong>Prezime:</strong> {{ order.lastName }}</p>
          <p><strong>Broj telefona:</strong> {{ order.phoneNumber }}</p>
          <p><strong>Adresa 1:</strong> {{ order.address1 }}</p>
        </div>
        <div class="col-md-6">
          <p><strong>Adresa 2:</strong> {{ order.address2 || 'N/A' }}</p>
          <p><strong>Grad:</strong> {{ order.city }}</p>
          <p><strong>Država:</strong> {{ order.country }}</p>
        </div>
      </div>

      <h5>Podaci o plaćanju</h5>
      <div class="row mb-3">
        <div class="col-md-6">
          <p><strong>Kartica korištena:</strong> Završava na {{ order.cardNumber.slice(-4) }}</p>
        </div>
        <div class="col-md-6">
          <p><strong>Ime na kartici:</strong> {{ order.nameOnCard }}</p>
        </div>
      </div>

      <h5>Naručeni artikli</h5>
      <div v-for="item in order.items" :key="item.id" class="item-detail mb-2 ps-3">
        <p><strong>Proizvod:</strong> {{ item.productName }}</p>
        <p><strong>Opis:</strong> {{ item.productDescription }}</p>
        <p><strong>Količina:</strong> {{ item.quantity }}</p>
        <p><strong>Cijena:</strong> ${{ item.productPrice }} x {{ item.quantity }} = ${{ item.price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import orderService from '../services/orderService';
import { useStore } from '../store/index';

export default {
  name: 'OrderOverview',
  data() {
    return {
      orders: [],
    };
  },
  async created() {
    const store = useStore();
    const customerId = store.user.id;

    try {
      const response = await orderService.getOrdersByCustomerId(customerId);
      this.orders = response.data.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
}

.order-card {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 5px;
}

.order-card h4, .order-card h5 {
  border-bottom: 2px solid gray;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.order-summary, .order-details, .order-items {
  margin-bottom: 1rem;
}

.item-detail {
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-left: 2px solid gray;
  border-radius: 5px;
}
</style>
