<template>
  <div class="container mt-5">
    <h2>Aktivacija računa</h2>
    <div v-if="activationStatus === 'loading'">
      <p>Vaš račun se aktivira, molimo pričekajte...</p>
    </div>
    <div v-if="activationStatus === 'success'">
      <p>Vaš račun je uspješno aktiviran! Sada se možete <router-link to="/login">prijaviti</router-link>.</p>
    </div>
    <div v-if="activationStatus === 'error'">
      <p>Došlo je do greške prilikom aktivacije računa. Aktivacijski link je možda istekao ili je nevažeći.</p>
    </div>
  </div>
</template>

<script>
import authService from '@/services/authService';
import { useRoute } from 'vue-router';

export default {
  name: 'AccountActivation',
  data() {
    return {
      activationStatus: 'loading',
    };
  },
  setup() {
    const route = useRoute();
    return { route };
  },
  async created() {
    const activationCode = this.route.params.activationCode;

    try {
      await authService.activateAccount(activationCode);
      this.activationStatus = 'success';
    } catch (error) {
      this.activationStatus = 'error';
    }
  },
};
</script>
